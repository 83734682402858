/* src/components/styles/reset.css */

/* Réinitialisation des styles */
*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: var(--font-family);
    background-color: #f9f9f9;
    color: var(--text-color);
    line-height: 1.6;
}

ul, ol {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}
