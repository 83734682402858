/* Maintenance.css */
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .maintenance-mode {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw; /* Utilise toute la largeur de l'écran */
}
  
  .maintenance {
    background: #fff;
    padding: 40px 20px;
    box-shadow: 0 8px 20px rgb(0 0 0 / 10%);
    border-radius: 10px;     
}
  
  h1 {
    font-size: 2.5rem;
    color: #e67e22;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.2rem;
    margin: 20px 0;
    line-height: 1.6; /* Meilleure lisibilité */
  }
  
  .button {
    display: inline-block; /* Pour un meilleur centrage visuel */
    padding: 10px 20px;
    background-color: #e67e22;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin-top: 20px; /* Espace entre le bouton et le texte */
  }
  
  .button:hover {
    background-color: #d35400;
  }
  
  /* Responsive Design */
  @media (width <= 768px) {
    .maintenance {
      padding: 30px 15px; /* Réduit les marges sur petits écrans */
    }
  
    h1 {
      font-size: 2rem; /* Réduit la taille du titre sur petits écrans */
    }
  
    p {
      font-size: 1rem;
    }
  
    .button {
      font-size: 0.9rem;
    }
  }
  