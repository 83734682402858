/* AuthForm.css */
.auth-container {
  width: 100%;
  max-width: 400px; /* Taille fixe pour les deux formulaires */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--primary-back-color);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  padding: 20px;
  text-align: center;
  margin: 0 auto; /* Centre horizontalement */
}

.auth-title {
  font-size: 24px;
  margin-bottom: 8px;
  color: var(--text-color);
}

.auth-subtitle {
  font-size: 16px;
  color: var(--placeholder-color);
  margin-bottom: 10px;
}

.google-btn {
  width: 100%;
  padding: 10px;
  margin: 4px 0;
  font-size: 16px;
  color: #fff;
  background-color: #db4437; /* Rouge Google */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-btn:hover {
  background-color: #c23321; /* Rouge plus sombre */
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: var(--divider-color);
}

.divider span {
  margin: 0 10px;
  color: var(--placeholder-color);
}

.auth-form {
  display: flex;
  flex-direction: column;
}

.auth-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
  background-color: var(--light-color);
  color: var(--text-color);
  border: 1px solid var(--input-border-color); /* Contour léger */
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.auth-input::placeholder {
  color: var(--placeholder-color);
}

.auth-input:focus {
  outline: none;
  border-color: var(--secondary-color);
}

.auth-remember-me {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  color: var(--placeholder-color);
  font-size: 14px;
}

.auth-remember-me input {
  margin-right: 10px;
}

.auth-submit {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-submit:hover {
  background-color: var(--hover-color);
}
