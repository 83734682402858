/* src/components/styles/variables.css */
:root {
    --primary-color: #2c3e50;
    --secondary-color: #1abc9c;
    --light-color: #ecf0f1;
    --text-color: #333;
    --font-family: 'Arial, sans-serif';
    --box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
    --primary-back-color: #fff;
    --input-border-color: #dcdcdc; /* Couleur du contour par défaut */  
    --hover-color: #1e2a38;

}
