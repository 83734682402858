/* Contact.css */

.contact-page {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    color: #333;
  }
  
  .contact-header {
    margin-bottom: 40px;
  }
  
  .contact-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .contact-header p {
    font-size: 1.2rem;
    color: #555;
    text-align: left;
  }
  
  .contact-content {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }
  
  .contact-form {
    flex: 1 1 500px;
    text-align: left;
  }
  
  .contact-form h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .form-group textarea {
    height: 150px;
    resize: vertical;
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .contact-info {
    flex: 1 1 300px;
  }
  
  .contact-info h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  
  .contact-info ul {
    list-style: none;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
  
  .contact-info ul li {
    margin-bottom: 10px;
  }
  
  .contact-info ul li a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-info ul li a:hover {
    text-decoration: underline;
  }
  
  .social-icons {
    margin-top: 20px;
  }
  
  .social-icons .social-icon {
    display: inline-block;
    margin-right: 10px;
    color: white;
    background-color: #007bff;
    padding: 10px 15px;
    border-radius: 50%;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .social-icons .social-icon:hover {
    background-color: #0056b3;
  }