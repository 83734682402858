/* LandingPage.css */
.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;       
}

.landing-header {    
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 40px 30px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
}

.landing-header h1 {
    font-size: 2.5rem;
}

.landing-features {
    margin-top: 20px;
    margin-bottom: 20px;
}
