/* src/components/pages/notfound/NotFound.css */
.not-found {
    display: flex;
    justify-content: center;
    align-items: center;     
    font-family: Arial, sans-serif;
    color: #333;
    gap: 40px;
    padding: 20px;
    margin-top: 300px;
    background-color: var(--light-color);
    margin-bottom: 20px;
  }
  
  .not-found-content {
    text-align: center;
    max-width: 600px;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
    border-radius: 10px;
  }
  
  .not-found-title {
    font-size: 6rem;
    font-weight: bold;
    color: #ff6f61;
    margin-bottom: 20px;
  }
  
  .not-found-subtitle {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .not-found-message {
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  .not-found-button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #ff6f61;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .not-found-button:hover {
    background-color: #e65b50;
  }
  