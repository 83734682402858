/* =========================
   Menu Principal Menu.css
========================= */

/* Conteneur principal */
.container {
  display: flex;
  justify-content: space-between; /* Menu gauche à gauche, menu droit à droite */
  align-items: center; /* Aligne les éléments verticalement */
  flex-wrap: nowrap; /* Empêche les menus de passer à la ligne */
  width: 100%; /* Menu occupe toute la largeur */
  font-size: 1.5rem;
}

/* Menu gauche */
.container-left {
  display: flex;
  justify-content: flex-start; /* Menu aligné à gauche */
  align-items: center; /* Centre verticalement */
  gap: 20px; /* Espacement entre les éléments */
}

/* Menu droit */
.container-right {
  display: flex;
  justify-content: flex-end; /* Menu aligné à droite */
  align-items: center; /* Centre verticalement */
  gap: 20px; /* Espacement entre les éléments */
  flex: 1; /* Prend tout l'espace disponible */
}

.header-container {
  display: flex;
  justify-content: space-between; /* Logo à gauche, menu à droite */
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  background-color: var(--primary-back-color);
}

/* Styles communs pour le menu gauche et droit */
.header-menu {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* =========================
   Styles Communs des Menus
========================= */
ul {
  list-style: none; /* Supprime les puces */
  margin: 0;
  padding: 0;
  display: flex; /* Aligne les items horizontalement */
}

ul li {
  position: relative;
  margin: 0 10px; /* Espacement horizontal entre les items */
}

ul a {
  text-decoration: none; /* Supprime le soulignement */
  color: #333; /* Couleur du texte */
  font-weight: 500;
  transition: color 0.3s ease; /* Animation fluide au survol */
}

ul a:hover {
  color: #007bff; /* Couleur au survol */
}

/* Ajuste l'icône pour qu'elle soit alignée avec le menu */
.menu-account {
  display: inline-flex;
  align-items: center; /* Centre verticalement */
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.menu-search, .menu-account {
  display: inline-flex !important; /* Forcer leur affichage */
  opacity: 1 !important;
  visibility: visible !important;
}

/* =========================
   Dropdown (Menus Déroulants)
========================= */
.account-dropdown {
  display: none; /* Par défaut, caché */
  position: absolute;
  top: calc(100% + 8px); /* Place le menu sous le header avec un espacement */
  right: -25px; /* Ajuste cette valeur pour décaler le menu vers la droite */
  background-color: var(--primary-back-color);
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  list-style: none;
  padding: 5px;
  z-index: 1000;
  width: 120px; /* Largeur fixe */
  text-align: right; /* Texte aligné à droite */
  box-sizing: border-box;

  /* Transition pour un effet fluide */
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Affiche le menu lorsqu'il est actif */
.menu-account.active .account-dropdown {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* Styles pour les éléments dans le dropdown */
.account-dropdown li {
  padding: 3px 5px; /* Réduit l'espacement interne */
  margin: 0; /* Supprime la marge */
  line-height: 1.4; /* Ajuste l'espacement vertical */
}

.account-dropdown li a {
  color: #333;
  text-decoration: none; /* Supprime le soulignement */
  display: block; /* Chaque lien prend toute la largeur */
  transition: color 0.3s ease;
  font-size: 1rem;
}

.account-dropdown li a:hover {
  color: #007bff; /* Couleur au survol */
}

/* =========================
   Recherche (Icône et Boîte de Saisie)
========================= */
.menu-search {
  display: flex;
  align-items: center; /* Centre verticalement */
  justify-content: center; /* Centre horizontalement */
  cursor: pointer; /* Curseur clic */
  transform: translateY(0); /* Ajustez la valeur pour remonter légèrement */
}

.menu-search i {
  font-size: 25px; /* Taille de l'icône */
  color: #007bff; /* Couleur */
  transition: color 0.3s ease; /* Animation fluide */
}

.menu-search i:hover {
  color: #0056b3; /* Couleur légèrement plus foncée au survol */
}

/* Boîte de saisie pour la recherche */
.search-box {
  position: absolute;
  top: 100%; /* Position sous le bas du header */
  right: 0;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;  
}

.search-input {
  width: 200px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.search-input:focus {
  outline: none;
  border-color: #007bff;
}

/* =========================
   Responsive Styles
========================= */
@media (width <= 768px) {
  .header-menu.menu-left,
  .header-menu.menu-right {
    display: none; /* Cache les menus classique */
  }

  /* =========================
     Menu Hamburger
  ========================= */
  .menu-ham {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-ham-dropdown {
    position: absolute;
    top: 100%; /* Place le menu sous l'icône */
    left: 20px; /* Décale le menu légèrement vers la droite */
    background-color: var(--primary-back-color);
    box-shadow: var(--box-shadow);
    border-radius: 5px;
    width: 200px; /* Largeur fixe */
    padding: 10px;
    z-index: 1000;
    text-align: left; /* Texte aligné à gauche */
  }

  .menu-ham-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column; /* Items en colonne */
  }

  .menu-ham-dropdown ul li {
    display: flex; /* Pour aligner l'icône et le texte */
    align-items: center; /* Centrer verticalement */
    gap: 8px; /* Espacement entre l'icône et le texte */
    margin: 4px 0; /* Espacement vertical */
    padding: 1px 0;
  }

  .menu-ham-dropdown ul li.divider {
    border-bottom: 1px solid var(--secondary-color); /* Ligne de séparation */
    margin: 1px 0; /* Espacement au-dessus et au-dessous */
  }

  .menu-ham-dropdown ul li:last-child {
    border-bottom: none;
  }

  .menu-ham-dropdown ul li a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1rem;
    display: block; /* Chaque lien prend toute la largeur */
    flex: 1; /* S'assure que le texte prend le reste de l'espace */
    transition: color 0.3s ease; /* Animation fluide */
  }

  .menu-ham-dropdown ul li a:hover {
    color: var(--secondary-color);
  }

  .menu-ham-dropdown ul li svg {
    font-size: 1.2rem; /* Taille des icônes */
    color: var(--primary-color);
  }
}

@media (width >= 769px) {
  .header-menu.menu-left,
  .header-menu.menu-right {
    display: flex; /* Affiche les menus classiques */
  }

  .menu-ham {
    display: none; /* Cache le menu hamburger */
  }
}
